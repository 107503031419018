.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.micIconPosition{
  position: absolute;
  right: 10px;
  top: 10px;
}
.inputHolderForbot{
  position: relative;
}
.inputTextField{
  width: 100%;
  height: 70px;
  border-radius: 10px;
  padding: 0px 20px;
}
.chartHistory{
  min-height: 700px;
}
.chartHistory ul {
  list-style: none;
  padding: 0px;
}
.client {
  color: green;
  text-align: left;
  margin-bottom: 10px;
}
.me{
  color: red;
  text-align: right;
  margin-bottom: 10px;
}
.suggestions {
  background: #ccc;
  display: inline-block;
  margin-bottom: 4px;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  margin-right: 15px;
}
.chatBoatHeader{
  background-color: #212529;
  color: #fff;
  padding: 15px;
  border-radius: 10px 10px 0px 0px;
}
.msg-box{
  padding: 0px;
}
.chatappend{
  padding: 10px;
}

body {
  height: 100vh;
}